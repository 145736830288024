/* App.css */
:root {
  --color-x: #040404;
  --color-y: #f9f9f9;
  --color-a: #123940;
  --color-b: #0c7489;
  --color-c: #119da4;
  --color-d: #4a6b7a;
  --color-background: linear-gradient(
    to bottom,
    var(--color-a),
    var(--color-b)
  );

  --color-x-hover: #333333;
  --color-y-hover: #e6e6e6;
  --color-a-hover: #0f2e32;
  --color-b-hover: #0a5b6e;
  --color-c-hover: #0e7b85;
}

.setupTab {
  background-color: var(--color-a);
  display: flex;
  overflow: auto;
  padding: 20px;
  flex-wrap: nowrap;
  height: 100%;
}

.setupInstructions {
  flex: 0 1 40%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.setupDetails {
  flex: 0 1 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fileUploads {
  border: 2px solid var(--color-y);
  border-radius: 10px;
  padding: 3vh 2vw;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.urlDetails {
  border: 2px solid var(--color-y);
  border-radius: 10px;
  padding: 0vh 2vw;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.supportDetails {
  border: 2px solid var(--color-y);
  border-radius: 10px;
  padding: 1vh 2vw;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.introQuestions {
  border: 2px solid var(--color-y);
  border-radius: 10px;
  padding: 1vh 2vw;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.setupVideo {
  border: 2px solid var(--color-y);
  border-radius: 10px;
  padding: 3vh 2vw;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.setupScripts {
  border: 2px solid var(--color-y);
  border-radius: 10px;
  padding: 0vh 1vw;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px;
}

.setupScripts,
.setupVideo {
  min-width: 300px;
}

.videoLabel {
  color: var(--color-y);
  font-size: 1.2rem;
  font-weight: bold;
  padding-right: 10px;
}

.videoWrapper {
  width: 100%;
  margin: 0 auto;
}

.responsiveVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.responsiveVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoOptions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px;
}

.dropdown {
  width: 200px;
  padding: 5px;
  border: 1px solid var(--color-y);
  border-radius: 5px;
  background-color: var(--color-a);
  color: var(--color-y);
  font-size: 1rem;
  margin-left: 10px;
}

.chatbotMessage {
  border: 2px solid var(--color-c);
  background-color: var(--color-b);
  color: var(--color-y);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chatbotMessage.highlight {
  border-color: #ff6f61;
  background-color: #ffab91;
  color: var(--color-highlight-text);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 15px rgba(255, 111, 97, 0.7);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 111, 97, 0.7);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(255, 111, 97, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 111, 97, 0.7);
  }
}

@media (max-width: 1200px) {
  .setupTab {
    flex-direction: column;
    align-items: center;
  }

  .setupInstructions,
  .setupDetails {
    flex: 1 1 100%;
    min-width: 0;
    margin-right: 0;
    width: 100%;
    align-items: center;
  }

  .setupScripts,
  .setupVideo,
  .supportDetails,
  .urlDetails,
  .introQuestions,
  .fileUploads {
    min-width: 300px;
    width: 100%;
  }
  .setupVideo {
    margin-bottom: 20px;
  }
  .videoOptions {
    margin-top: 0px;
    margin-left: 10px;
  }
}

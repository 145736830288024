.container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none; /* Hides the scrollbar for Firefox */
  -ms-overflow-style: none; /* Hides the scrollbar for IE and Edge */
  height: 110%;
  background-color: var(--color-a);
}

/* Hides the scrollbar for Chrome, Safari, and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 2px solid #6b7a7e; 
}

.header span {
  margin: 5px 0;
}

.header .selectedPlan {
  font-size: 22px; /* Larger font size */
  font-weight: bold;
  color: #ffffff; /* White text color */
  margin-left: 15px;
}

.header .renewDate {
  font-size: 16px; /* Slightly smaller font size */
  color: #e0e0e0; /* Lighter text color */
  margin-left: 15px;
}

.planContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center; /* Center-align plan cards */
  padding-left: 10%;
  padding-right: 10%;
}

.plan {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Increase padding */
  border: 1px solid #ddd; /* Lighter border color */
  border-radius: 8px; /* More rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition */
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.plan:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.plan h3 {
  font-size: 22px; /* Larger font size for plan name */
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff; /* White text color */
}

.plan h4 {
  font-size: 20px;
  color: #c0d6e4;
  margin-bottom: 15px;
}

.plan ul {
  list-style: none;
  padding: 0;
  font-size: 16px; /* Slightly larger text */
  color: #f0f0f0; /* Softer text color */
}

.plan button {
  padding: 10px 20px; /* Increased padding */
  font-size: 16px;
  border: none;
  border-radius: 20px; /* Fully rounded corners */
  background-color: #6699cc; /* Subtle button color */
  color: #ffffff; /* White text color */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
}

.plan button:hover {
  background-color: #557a99; /* Slightly darker shade on hover */
  transform: scale(1.05); /* Slight grow on hover */
}

.plan .selectedButton {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #7a9db0; /* Subtle color for selected button */
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}

.plan .selectedButton:hover {
  background-color: #6a8d9c; /* Slightly darker color on hover */
}

.currentPlan {
  border: 4px solid #feffff; /* Darker border color */
  background-color: #43606e;
}

.billingContactContainer {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding-left: 80px;
  padding-right: 80px;
  border-top: 2px solid #6b7a7e; 
  margin-top: 20px; /* Space between the plans and container */
  height: 40%;
}

.billingHistory {
  flex: 1;
  padding: 10px;
  height: 100%;
}

.billingHistory h3 {
  font-size: 22px; /* Matching the size of "Selected Plan:" */
  color: #ffffff; /* Consistent color */
  margin-bottom: 10px; /* Space between the header and list */
}

.billingHistory .listContainer {
  height: 100%; /* Set a maximum height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Add some padding to avoid text being cut off by the scrollbar */
  border: 1px solid #ddd; /* Add a border to distinguish the scrollable area */
  border-radius: 8px; /* Rounded corners */
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px; /* Add padding inside the container */
}

.billingHistory ul {
  list-style: none; /* Removes bullet points */
  padding: 0;
  margin: 0;
}

.billingHistory li {
  font-size: 16px; /* Consistent font size with plan details */
  color: #f0f0f0; /* Softer text color */
  padding: 5px 10px; /* Padding to space out list items */
  margin-bottom: 5px; /* Space between list items */
  background-color: #5b7c8a; /* Slightly darker background for list items */
  border-radius: 4px; /* Rounded corners for list items */
}

.billingHistory span {
  display: inline-block;
  min-width: 120px; /* Ensures alignment if dates or prices vary in width */
}

.billingHistory p {
  font-size: 16px; /* Same font size as list items for consistency */
  color: #e0e0e0; /* Lighter color for non-critical info */
}

.contactForm {
  flex: 1;
  padding: 10px;
}

.contactForm h3 {
  font-size: 22px; /* Matching the size of "Selected Plan:" */
  color: #ffffff; /* Consistent color */
  margin-bottom: 10px; /* Space between the header and list */
}

.contactForm .formGroup {
  margin-bottom: 15px;
}

.contactForm label {
  display: block;
  font-size: 16px; /* Consistent font size */
  color: #f0f0f0; /* Softer text color */
  margin-bottom: 5px;
}

.contactForm input,
.contactForm textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px; /* Consistent font size */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.contactForm button {
  padding: 10px 20px;
  font-size: 16px; /* Consistent font size */
  border: none;
  border-radius: 20px; /* Fully rounded corners */
  background-color: #6699cc; /* Subtle button color */
  color: #ffffff; /* White text color */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
}

.contactForm button:hover {
  background-color: #557a99; /* Slightly darker shade on hover */
  transform: scale(1.05); /* Slight grow on hover */
}

.autoRenewal {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  margin-left: 15px;
}

.autoRenewal span {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  vertical-align: middle; /* Ensures the switch is aligned vertically */
  margin-top: 12px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px; /* Adjusted size */
  width: 17px; /* Adjusted size */
  left: 1px; /* Centered the button */
  bottom: 2px; /* Centered the button */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2a4f5f;
}

input:checked + .slider:before {
  transform: translateX(24px); /* Adjusted to match the new size */
}

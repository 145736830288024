.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2c5364;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}

.leftSection {
  display: flex;
  gap: 20px;
}

.leftSection > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.leftSection input {
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: #375a7f;
  color: #ffffff;
}

.leftSection input::placeholder {
  color: #d1d1d1;
}

.rightSection {
  display: flex;
  gap: 10px;
}

.originalButton,
.revisionButton {
  padding: 5px 10px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  background-color: #2c3e50; /* Default unselected background color */
}

.originalButton:hover,
.revisionButton:hover {
  background-color: #3b5563;
}

.originalButton:active,
.revisionButton:active,
.originalButton.selected,
.revisionButton.selected {
  background-color: #ffffff; /* Selected background color */
  color: #2c3e50; /* Change text color for contrast */
}

.newRevisionButton {
  padding: 5px 10px;
  background-color: #5cb85c; /* Distinct green color */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.newRevisionButton:hover {
  background-color: #4cae4c; /* Darker green for hover */
}

.newRevisionButton:active,
.newRevisionButton.selected {
  background-color: #449d44; /* Even darker green for active/selected */
}

.selected {
  background-color: #212e3b;
  color: #ffffff;
}

.saveButton {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.saveButton:hover {
  background-color: #45a049;
}

/* LandingPage.module.css */
.landingContainer {
  background: var(--color-background);
}

.landingContainer > div {
  position: relative;
  padding: 20px 0;
}

.landingContainer > div:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  height: 1.5px;
  background-color: var(--color-y);
  border-radius: 20px;
}

@media (max-width: 768px) {
  .landingContainer > div:not(:last-child)::after {
    width: 90%;
  }
}

.aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.termsContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Make sure it's on top */
  background-color: var(--color-y); /* Adjusted background color */
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  height: 60vh; /* Adjusted to give more space for content */
}

.header {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center; /* Centered the text */
  font-family: "Times New Roman", Times, serif; /* Legal style font */
}

.updateDate {
  font-size: 12px;
  color: gray;
  margin-bottom: 20px;
  text-align: center; /* Centered the text */
  font-family: "Times New Roman", Times, serif; /* Legal style font */
}

.contentContainer {
  overflow-y: auto;
  flex-grow: 1;
  padding-right: 10px; /* Add padding to prevent content from touching the scrollbar */
}

.content {
  font-size: 14px;
  margin-bottom: 20px;
  font-family: "Times New Roman", Times, serif; /* Legal style font */
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* Add margin to separate buttons from content */
}

.cancelButton,
.agreeButton {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.cancelButton {
  background-color: #b0b0b0;
  color: var(--color-y);
  margin-right: 10px; /* Add space between buttons */
}

.cancelButton:hover {
  background-color: grey; /* Subtle grey for hover */
}

.agreeButton {
  background-color: var(--color-a);
  color: var(--color-y);
}

.agreeButton:hover {
  background-color: var(--color-a-hover);
}

.chatDisplay {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 10px;
}

.deleteRevisionButton {
  background-color: #ff4d4f; /* A bright red for visibility */
  color: #ffffff; /* White text for contrast */
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  border-radius: 5px; /* Rounded corners */
  outline: none; /* Remove default focus outline */
}

.deleteRevisionButton:hover {
  background-color: #ff7875; /* Lighter red on hover */
}

.deleteRevisionButton:active {
  background-color: #d9363e; /* Darker red when the button is clicked */
}

.deleteRevisionButton:focus {
  box-shadow: 0 0 0 2px rgba(255,77,79,0.4); /* Focus ring around the button */
}

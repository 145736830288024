/* AdminNavBar.module.css */
.adminNavBar {
  display: flex;
  flex-wrap: wrap; /* Allows buttons to wrap to the next line */
  justify-content: space-between; /* Distributes space between children */
  align-items: center; /* Aligns items to the center vertically */
  width: 100%; /* Full width */
  background-color: #ffffff;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.pontoAILogo {
  width: 50px; /* Adjust the width as necessary */
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 35px;
}

.adminTab {
  background-color: transparent;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  margin: 2px 8px 2px 0; /* Margin between buttons */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;
  color: #204056; /* Sets the text color to navy blue */
}

.adminTab:hover {
  background-color: #e2e6ea;
}

.adminSelected {
  background-color: #e9ecef;
  font-weight: bold;
}

.navProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #204056;
  color: #f0f0f0; /* Off-white text color */
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid transparent; /* Initial border color */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.navProfile:hover {
  border-color: #add8e6; /* Border color on hover */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Subtle shadow effect */
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff; /* White background */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); /* Soft shadow */
  z-index: 1;
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure rounded corners for all items */
  margin-top: 10px; /* Slight margin to separate from the nav bar */
  width: 200px; /* Consistent width */
}

.dropdownItem {
  padding: 12px 16px; /* Adequate padding */
  text-decoration: none;
  display: flex; /* Use flex for proper alignment */
  justify-content: space-between; /* Space between label and toggle */
  align-items: center; /* Center items vertically */
  color: #003366; /* Dark blue text to match other buttons */
  font-family: "Roboto", sans-serif; /* Consistent font */
  font-size: 0.9em; /* Consistent font size */
  font-weight: 700;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  width: 100%; /* Full width */
  background: none; /* Remove default button styles */
  border: none; /* Remove default button styles */
  cursor: pointer; /* Pointer cursor for buttons */
}

.dropdownItem:hover {
  background-color: #f5f5f5; /* Brighter blue on hover */
}

.dropdownItem.noHover:hover {
  background-color: #ffffff; /* Keeps the background color the same as non-hover state */
  color: #003366; /* Keeps the color the same as non-hover state */
}

.dropdownDivider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 8px 0;
}

.authButtons {
  display: flex;
  align-items: center;
  position: relative; /* Make this container relative */
  margin-left: auto; /* Ensures authButtons are pushed to the right */
}

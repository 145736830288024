.chatContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #7a9cbd;
  overflow: hidden; /* Prevent overflow */
}

.adminChatNavBarComponent {
  flex: 0 1 auto;
}

.adminChatDisplayContainer {
  flex: 1 1 auto;
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.adminChatSendContainer {
  flex: 0 1 auto;
}

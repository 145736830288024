.setupScriptsContainer {
  background-color: #2a4d53;
  color: var(--color-y);
  padding: 20px;
  border-radius: 8px;
}

.scriptSection {
  margin-bottom: 20px;
}

.toggleSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggleLabel {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 16px;
}

.enabled {
  color: #32cd32;
}

.disabled {
  color: #ff6347; 
}

.toggleInput {
  display: none;
}

.toggleInput + span {
  display: inline-block;
  width: 34px;
  height: 20px;
  background-color: #ccc;
  border-radius: 34px;
  position: relative;
  transition: background-color 0.3s;
  margin-left: 10px;
  cursor: pointer;
}

.toggleInput + span::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: transform 0.3s;
}

.toggleInput:checked + span {
  background-color: #76c7c0;
}

.toggleInput:checked + span::before {
  transform: translateX(14px);
}

.scriptContainer {
  display: flex;
  align-items: center;
}

.scriptTextarea {
  width: 100%;
  height: auto;
  line-height: 1.5;
  resize: none;
  font-family: monospace;
  background-color: #4a6b73;
  color: var(--color-y);
  border: 1px solid var(--color-y);
  border-radius: 4px;
}

.copyButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
}

.copyButton img {
  width: 24px;
  height: 24px;
}

.copiedMessage {
  color: #a8d5e2;
  margin-top: 10px;
  display: block;
}

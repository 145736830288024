/* AdminConversationsPage.module.css */

.adminConversationsParent {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent overflow */
}

.adminConversationsComponent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden; /* Prevent overflow */
}

.adminCompanyBarContainer {
  height: 100%;
  flex-shrink: 0; /* Prevent shrinking */
  flex-grow: 0; /* Prevent growing */
  flex-basis: auto; /* Adjust to content size */
}

.adminConversationsContainer {
  height: 100%;
  width: 250px; /* Set explicit width */
  flex-shrink: 0; /* Prevent shrinking */
  overflow-y: auto; /* Enable scrolling if content overflows */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.adminChatContainer {
  height: 100%;
  flex-grow: 1; /* Allow growing to take available space */
  overflow-y: auto; /* Enable scrolling if content overflows */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

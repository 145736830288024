.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: var(--color-y);
  box-sizing: border-box;
}

.logo img {
  width: 200px;
}

.navLinks {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  gap: 13px;
}

.navLinks a {
  text-decoration: none;
  color: var(--color-x);
  font-size: 22px;
  font-weight: bold; /* Added to make the NavLinks thicker */
  padding: 8px 12px; /* Added to make the NavLinks thicker */
}

.navLinks a:hover {
  color: var(--color-x-hover); /* Added hover effect */
}

.authButtons {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.authButtons a {
  padding: 6px 15px;
  text-decoration: none;
  color: var(--color-x);
  border: 1px solid var(--color-x);
  border-radius: 4px;
  white-space: nowrap;
}

.authButtons a:hover {
  background-color: var(--color-y-hover);
}

.authButtons .signup {
  background-color: var(--color-a);
  border: none;
  color: var(--color-y);
}

.authButtons .signup:hover {
  background-color: var(--color-a-hover);
  color: var(--color-y-hover);
}

.profileContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.profileIcon {
  background-color: var(--color-a);
  color: var(--color-y);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.dropdownMenu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: var(--color-y);
  border: 1px solid #ccc; /* Subtle grey border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 150px;
  margin-top: -10px;
  text-align: center;
}

.dropdownMenu a {
  padding: 10px 15px;
  text-decoration: none;
  color: var(--color-x);
  border-radius: 0; /* No rounded borders */
  border: none !important;
  border-bottom: 1px solid #ccc !important; /* Subtle grey border */
}

.dropdownMenu button {
  padding: 10px 15px;
  text-decoration: none;
  color: var(--color-x);
  border-bottom: 1px solid #ccc !important; /* Subtle grey border */
  border-radius: 0; /* No rounded borders */
  background: none;
  border: none !important;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.dropdownMenu a:last-child {
  border-bottom: none;
}

.dropdownMenu a:hover {
  background-color: var(--color-y-hover);
}

.dropdownMenu button:hover {
  background-color: var(--color-y-hover);
}

@media (max-width: 768px) {
  .logo img {
    content: url("../assets/PontoAISmallerTxt.svg");
    width: 60px;
  }

  .authButtons {
    flex: none;
    justify-content: flex-end;
  }
}

@media (max-width: 500px) {
  .navLinks a {
    display: none;
  }

  .navLinks {
    flex: none;
  }
}

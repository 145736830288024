.chatsTab {
  display: flex;
  height: 100%; /* This ensures it fills the parent's height */
}

.chatsAreaContainer {
  flex: 1; /* Take up the remaining space */
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allow scrolling if content overflows */
  background-color: var(--color-d);
}

.messageContainer {
  display: flex;
  margin: 10px 0;
  max-width: 100%;
}

.messageContainer.user {
  justify-content: flex-end;
}

.messageContainer.bot,
.messageContainer.admin,
.messageContainer.context,
.messageContainer.url {
  justify-content: flex-start;
}

.messageContent {
  max-width: 70%;
  padding: 10px;
  padding-bottom: 25px; /* Add padding to accommodate the icons */
  border-radius: 10px;
  position: relative;
  word-wrap: break-word;
}

.messageContainer.user .messageContent {
  background-color: #cde4d6; /* Subtle light green */
  text-align: right;
  border-bottom-right-radius: 0;
}

.messageContainer.bot .messageContent {
  background-color: #e2e2e2; /* Subtle light gray */
  text-align: left;
  border-bottom-left-radius: 0;
}

.messageContainer.admin .messageContent {
  background-color: #d6d6d6; /* Slightly darker subtle gray */
  text-align: left;
  border-bottom-left-radius: 0;
}

.messageContainer.context .messageContent {
  background-color: #fdeacc; /* Subtle light peach */
  text-align: left;
  border-bottom-left-radius: 0;
}

.messageContainer.url .messageContent {
  background-color: #cce7f3; /* Subtle light blue */
  text-align: left;
  border-bottom-left-radius: 0;
}

.messageText {
  display: block;
  margin-bottom: 5px;
}

.messageTimestamp {
  font-size: 0.8em;
  color: #888;
}

.revisionIcons {
  position: absolute;
  bottom: 5px; /* Adjusted to position slightly lower */
  right: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.icon {
  cursor: pointer;
  margin: 0 2px;
  font-size: 1em; /* Smaller icon size */
  color: #909090;
}

.icon:hover {
  color: #666666;
}

/* Background color for the chat UI */
.chatUI {
  background-color: #7a9cbd;
  padding: 20px;
  border-radius: 10px;
}

.senderLabel {
  font-weight: bold;
}

.senderLabelRight {
  text-align: right;
  margin-right: 10px;
}

.senderLabelLeft {
  text-align: left;
  margin-left: 10px;
}

.editTextArea {
  width: 100%; /* Relative to its parent */
  height: auto; /* Adjust based on content */
  min-height: 180px; /* Minimum height */
  padding: 8px;
  box-sizing: border-box;
  resize: none; /* Prevent resizing */
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Ensure that the messageContent takes 80% width of its parent when editing */
.messageContent.editing {
  width: 80%;
  position: relative; /* Ensure relative positioning for absolute positioning of icons */
  padding-right: 40px; /* Add padding to accommodate icons */
}

.editIcons {
  position: absolute;
  right: 55px;
  top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.iconCheck,
.iconCancel {
  cursor: pointer;
  font-size: 1.2em; /* Adjust size as needed */
}

.iconCheck:hover,
.iconCancel:hover {
  color: #4caf50; /* Green for save, adjust as needed */
  color: #f44336; /* Red for cancel, adjust as needed */
}

.contextButtons {
  display: flex;
  gap: 10px;
  margin-left: 40px;
  margin-top: -25px;
}

.contextButton {
  padding: 8px;
  font-size: 12px; /* Adjust as needed */
  cursor: pointer;
}

.iconPositive {
  color: green;
}

.iconNegative {
  color: red;
}

.supportDetailsContainer {
  background-color: #2a4d53;
  color: var(--color-y);
  border-radius: 8px;
}

.contactInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contactInputGroup {
  display: flex;
  align-items: center;
}

.contactInputGroup label {
  margin-right: 10px;
  font-size: 16px;
}

.inputEmailField {
  width: 100%;
  line-height: 1.5;
  font-family: monospace;
  background-color: #4a6b73;
  color: var(--color-y);
  border: 1px solid var(--color-y);
  border-radius: 4px;
  padding: 8px;
  margin-right: 20px;
}

.inputPhoneField {
  width: 100%;
  line-height: 1.5;
  font-family: monospace;
  background-color: #4a6b73;
  color: var(--color-y);
  border: 1px solid var(--color-y);
  border-radius: 4px;
  padding: 8px;
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -10px;
}

.saveButton,
.cancelButton {
  background: none;
  border: 1px solid var(--color-y);
  color: var(--color-y);
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.cancelButton:hover {
  background-color: var(--color-y-hover);
  color: #2a4d53;
}

.saveButton:hover {
  background-color: var(--color-a-hover);
  color: var(--color-y);
}

@media (max-width: 600px) {
  .contactInputGroup {
    flex-direction: column;
    align-items: center;
  }

  .contactInputGroup label {
    margin-left: 5%;
    margin-bottom: 5px;
    align-self: flex-start;
    width: 85%;
  }

  .inputEmailField,
  .inputPhoneField {
    margin-right: 0;
    margin-bottom: 20px;
    width: 85%;
  }

  .supportDetailsContainer h3 {
    margin-left: 5%;
  }
}

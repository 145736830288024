/* Main content area */
.mainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

/* Form container styling */
.formContainer {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Title styling */
.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #001970;
    text-align: center;
}

/* Form styling */
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Input group styling */
.inputGroup {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
}

/* Update button styling */
.updateButton {
    background-color: #4169e1;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    padding: 12px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.updateButton:hover {
    background-color: #3655b3;
}

/* Back to sign in button styling */
.signInButton {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
}

.signInButton:hover {
    text-decoration: underline;
    background: none;
    color: #0056b3;
}

.errorText {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.successText {
    color: green;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.lineLeft,
.lineRight {
  position: absolute;
  width: 2px;
  height: 0; /* Initially set to 0 */
  background-color: var(--color-y);
  top: 15vh; /* Start from the top */
}

.lineLeft {
  left: calc(
    (100% - 800px) / 2 / 2
  ); /* Adjust the position to be halfway between the screen edge and the aboutSection */
}

.lineRight {
  right: calc(
    (100% - 800px) / 2 / 2
  ); /* Adjust the position to be halfway between the screen edge and the aboutSection */
}

.aboutSection {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin: 30px auto 50px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.aboutSection h1 {
  color: var(--color-y);
  font-size: 3.8rem;
  margin-bottom: 20px;
  text-align: center;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  border: 2px solid var(--color-y);
  border-radius: 15px;
  padding: 50px 4vw;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
}

.icon {
  width: 250px;
  height: 250px;
  fill: var(--color-y);
}

.iconLeft {
  margin-right: 60px;
}

.iconRight {
  margin-left: 60px;
}

.textRight {
  color: var(--color-y);
  text-align: right;
  flex: 1;
}

.textLeft {
  color: var(--color-y);
  text-align: left;
  flex: 1;
}

.textRight,
.textLeft {
  margin-top: -40px;
}

.textRight h3,
.textLeft h3 {
  margin: 0;
  font-size: 2rem;
}

.textRight p,
.textLeft p {
  margin: 0;
  font-size: 1.25rem;
  margin-top: 30px;
}

.item {
  will-change: transform, opacity;
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .item {
    flex-direction: column;
    text-align: center;
    padding: 30px 2vw;
  }

  .iconLeft,
  .iconRight {
    margin: 0 0 20px 0;
  }

  .aboutSection {
    margin-top: 0px;
  }

  .aboutSection h1 {
    font-size: 3rem;
  }

  .textRight,
  .textLeft {
    order: 1;
    text-align: center;
  }

  .textRight h3,
  .textLeft h3 {
    font-size: 1.5rem; /* Reduced font size */
  }

  .textRight p,
  .textLeft p {
    font-size: 1rem; /* Reduced font size */
  }

  .textRight,
  .textLeft {
    margin-top: 0px;
  }

  .textRight p,
  .textLeft p {
    margin-top: 10px;
  }
  .lineLeft,
  .lineRight {
    display: none; /* Hide lines on small screens */
  }
}

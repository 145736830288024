.knowledgeBase {
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    padding: 20px;
    font-family: Arial, sans-serif;
    height: 100vh;
}


.searchSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    width: 100%;
}

.searchSection label {
    margin-bottom: 5px;
    font-weight: bold;
}

.searchSection input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.searchSection button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.searchSection button:hover {
    background-color: #0056b3;
}

.infoSection {
    margin-top: 20px;
    display: flex;
    width: 100%;
    height: 80%;
}

.infoSectionLeft {
    flex: 1;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.infoSectionRight {
    flex: 2;
    max-width: 70%;
    display: flex;
    flex-direction: column;
}

.infoItem label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.infoItem input,
.infoItem textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

textarea#companyKnowledgeBase {
    flex-grow: 1; /* Allow textarea to take available space */
}

/* Save button styling */
.saveButton, .generateButton {
    margin-top: 10px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 49%; /* Slightly less than half to fit both buttons */
    margin-right: 2%; /* Space between buttons */
}

.saveButton {
    background-color: #28a745;
}

.saveButton:hover {
    background-color: #218838;
}

.generateButton {
    background-color: #007bff; /* Different color to distinguish */
    margin-right: 0; /* No margin on the right for the second button */
}

.generateButton:hover {
    background-color: #0056b3;
}

.saveStatus {
    font-size: 14px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 6px;
    text-align: center;
    font-weight: 500;
    transition: color 0.3s; /* Transition on color change */
}

.saveStatus.success {
    font-size: larger;
    color: #2fd345; /* Green for success */
}

.saveStatus.error {
    font-size: larger;
    color: #d32f2f; /* Red for error */
}

.referenceUrlsSection {
    margin-bottom: 20px;
    max-height: 30vh; /* Set a maximum height of 30% of the viewport height */
    overflow-y: auto; /* Enable vertical scrolling when content exceeds the max height */
}

.referenceUrlForm {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.referenceUrlList {
    list-style: none;
    padding: 0;
}

.referenceUrlList li {
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.referenceUrlList li input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.referenceUrlList li button {
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.referenceUrlList li button:hover {
    background-color: #c82333;
}

.referenceUrl {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.referenceUrl input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.referenceUrl button {
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.referenceUrl button:hover {
    background-color: #c82333;
}

.addReferenceUrl {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.addReferenceUrl input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.addReferenceUrl button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addReferenceUrl button:hover {
    background-color: #0056b3;
}

.infoSection input {
    background-color: #ffffff;
    color: black;
}
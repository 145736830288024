.contactContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-left: 10vw;
  padding-right: 10vw;
  gap: 20px;
  padding-top: 60px;
}

.leftSide,
.rightSide {
  flex: 1 1 45%;
  min-width: 300px;
}

.leftSide h2,
.leftSide p {
  margin: 0;
  word-wrap: break-word;
  color: var(--color-y);
}

.leftSide h2 {
  font-size: 4.8em;
  margin-bottom: 10px; 
  margin-top: 30px;
}

.leftSide h2,
.leftSide p {
  margin-left: 5vw;
}

.leftSide p {
  font-size: 2em;
  margin-bottom: 20px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--color-y-hover);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: var(--color-y);
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  color: var(--color-y);
  font-weight: bold;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--color-x);
  border-radius: 4px;
  background-color: var(--color-y) !important;
  color: var(--color-x) !important;
  box-sizing: border-box;
  font-size: 1rem;
}

.formGroup input,
.formGroup textarea {
  color: var(--color-x);
}

.formGroup textarea {
  height: 150px;
}

.formGroup input:focus,
.formGroup textarea:focus {
  border-color: var(--color-c);
  outline: none;
}

.submitButton {
  padding: 15px 20px;
  background-color: var(--color-a);
  color: var(--color-y);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.2em;
}

.submitButton:hover {
  background-color: var(--color-a-hover);
}

.successMessage {
  font-size: 1.5em;
  color: var(--color-y);
  text-align: center;
  padding-top: 25vh;
  padding-bottom: 25vh;
  border: 1px solid var(--color-y-hover);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 1180px) {
  .leftSide,
  .rightSide {
    flex-basis: 100%;
  }

  .leftSide {
    text-align: center;
    margin-bottom: 50px;
    margin-top: -40px;
  }
}

@media (max-width: 768px) {
  .leftSide h2 {
    font-size: 3rem;
  }

  .leftSide {
    margin-top: -40px;
    margin-bottom: 30px;
  }
}

.dashboardBar {
  width: 180px;
  background-color: var(--color-a-hover);
  color: var(--color-y);
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  flex-shrink: 0;
  overflow-y: auto; /* Enable vertical scroll */
}

.link {
  padding: 10px 20px;
  color: var(--color-y);
  text-decoration: none;
  text-align: center;
}

.activeLink {
  background-color: var(--color-a);
  border-top: 0.5px solid var(--color-y);
  border-bottom: 0.5px solid var(--color-y);
}

.link:hover {
  background-color: var(--color-a);
}

@media (max-width: 768px) {
  .dashboardBar {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
  }

  .link {
    padding: 10px 0;
    flex: 1;
    border-bottom: none; /* Remove individual link bottom border */
  }

  .activeLink {
    border-left: 1px solid var(--color-y);
    border-right: 1px solid var(--color-y);
    border-top: 1px solid var(--color-y);
    border-bottom: none; /* Ensure no bottom border for the active link */
  }

  .link:not(.activeLink) {
    border-bottom: 1px solid var(--color-y); /* Add bottom border for non-active links */
  }

  /* Remove subtle borders in small screen */
  .activeLink {
    border-top: none;
    border-bottom: none;
  }
}

/* Add subtle top and bottom border for active links on larger screens */
@media (min-width: 769px) {
  .activeLink {
    border-top: 1px solid var(--color-y);
    border-bottom: 1px solid var(--color-y);
  }
}

.landingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  padding: 4rem;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.textSection {
  max-width: 45%;
  color: var(--color-y);
  margin: 0;
  text-align: left;
}

.textSection h1 {
  font-size: 3.8rem;
  margin-bottom: 2rem;
  margin-top: 0;
}

.textSection p {
  font-size: 1.3rem;
  margin-bottom: 0rem;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: center;
}

.getStartedButton,
.pricingButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 17px;
  cursor: pointer;
  flex: 1;
  max-width: 150px;
  text-align: center;
}

.getStartedButton {
  background-color: #ffd54f;
  color: var(--color-x);
}

.pricingButton {
  background-color: var(--color-c);
  color: var(--color-y);
}

.videoSection {
  max-width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoSection iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
  height: 370px;
  margin-top: 8px;
}

.priceHighlight {
  color: #ffd54f;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top: 6px solid var(--color-y);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.loadingContainer p {
  color: var(--color-y);
  font-size: 1.5rem;
  margin-top: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1600px) {
  .landingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .landingContainer {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    padding-top: 30px;
  }

  .textSection {
    max-width: 100%;
    text-align: center;
  }
  .textSection h1 {
    font-size: 2.3rem;
    margin-bottom: 2rem;
    margin-top: 0;
  }
  .textSection p {
    font-size: 1rem;
    margin-bottom: 0rem;
  }

  .videoSection {
    max-width: 90%;
    height: auto;
    margin-bottom: 20px;
  }

  .videoSection iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    border: none;
    height: 250px;
    margin-top: 8px;
  }

  .buttonContainer {
    gap: 1rem;
    justify-content: center;
  }

  .getStartedButton,
  .pricingButton {
    width: 50%;
  }

  .loadingContainer {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

/* StripeCheckoutForm.module.css */
.subscriptionDetails {
  background: #4a6b7a; /* Matching background color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px; /* Extra space below the details */
}

.subscriptionInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; /* Space between each info row */
}

.planLabel,
.priceLabel,
.renewDateLabel {
  font-size: 14px;
  color: #c0d6e4; /* Matching text color */
  font-weight: bold;
}

.planName,
.priceValue,
.renewDateValue {
  font-size: 16px;
  color: #ffffff; /* Matching text color */
  font-weight: normal;
}

.formContainer {
  background: #4a6b7a; /* Matching background color */
  padding: 30px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 40px auto;
  box-sizing: border-box;
  position: relative;
}

.inputRow {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.inputRow input {
  background-color: white !important;
  color: black;
}

.label {
  font-size: 14px;
  color: #c0d6e4; /* Matching text color */
  margin-bottom: 5px;
  font-weight: bold;
}

.uniformInput {
  background: #3c5975; /* Matching input background color */
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #ffffff; /* Matching text color */
}

.cardElement {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  background: #ffffff; /* Matching input background color */
  color: black; /* Matching text color */
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

.payButton {
  background-color: #6699cc; /* Matching button color */
  color: #ffffff; /* Matching text color */
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  width: 100%;
  transition: background-color 0.3s ease;
}

.payButton:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.payButton:hover {
  background-color: #557a99; /* Slightly darker shade on hover */
}

.expiryAndCvc {
  display: flex;
  gap: 20px; /* Clearly defined gap between Expiry and CVC */
  width: 100%;
}

.expiryWrapper,
.cvcWrapper {
  flex: 1;
  display: flex;
  flex-direction: column; /* Ensures label is above the input */
}

.cardExpiryElement,
.cardCvcElement {
  flex: 1; /* Flex property to ensure input fills its container */
  padding: 12px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  background-color: white !important;
  color: black; /* Matching text color */
}

.error {
  color: #ffd2d2; /* Vivid red for visibility */
  background-color: #d8000c; /* Light red/pink background */
  border: 1px solid #d8000c; /* Red border to highlight the box */
  padding: 10px 15px; /* Ample padding for better readability */
  border-radius: 5px; /* Rounded corners for a softer look */
  margin-top: 10px; /* Space from the last form element */
  font-weight: bold; /* Bold font for emphasis */
  display: block; /* Ensures it takes its own line */
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Smooth transition for visibility */
}

/* Ensure the error message fades in when there is a message */
.error:not(:empty) {
  opacity: 1;
}

.successContent {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.successContent h2 {
  font-size: 24px;
  color: #ffffff; /* Matching text color */
  font-weight: bold;
  margin-bottom: 10px;
}

.successContent p {
  font-size: 16px;
  color: #c0d6e4; /* Matching text color */
  margin-bottom: 5px;
}

.successContent .highlight {
  font-size: 18px;
  color: #ffffff; /* Matching text color */
  font-weight: 700; /* Lighter bold effect */
}

.successContent .subText {
  font-size: 14px;
  color: #c0d6e4; /* Matching text color */
}

.closeButton {
  margin-top: 20px;
  background-color: #6699cc; /* Matching button color */
  color: #ffffff; /* Matching text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
  background-color: #557a99;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2a4f5f;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.closeButton {
  position: absolute;
  top: -12px;
  right: 10px;
  padding: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

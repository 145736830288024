.companyBarContainer {
  background-color: #2c5364;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  width: 240px;
}

.companySearchBar {
  margin-bottom: 10px;
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: #375a7f;
  color: #ffffff;
}

.companySearchBar::placeholder {
  color: #d1d1d1;
}

.companyList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.companyItem {
  padding: 10px;
  margin: 0;
  background-color: #2c5364;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #ffffff;
}

.companyItem:first-child {
  border-top: 1px solid #ffffff;
}

.companyItem:last-child {
  border-bottom: 1px solid #ffffff;
}

.companyItem:hover {
  background-color: #3b6978;
}

.companyItem:active, .companyItem.selected {
  background-color: #1a3a4a;
}

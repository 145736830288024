.sendContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9; /* Example background color */
    border-top: 1px solid #ddd; /* Example border color */
  }
  
  .input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd; /* Example border color */
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .sendButton {
    background-color: #007bff; /* Example primary color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  
  .sendButton:hover {
    background-color: #0056b3; /* Example darker primary color */
  }
  
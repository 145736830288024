.usageContent {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--color-a);
  height: 100%;
}

.customerQueries {
  flex: 1;
  max-width: 45%;
  padding-right: 20px;
}

.usageContainer {
  flex: 1;
  width: 55%;
  display: flex;
  flex-direction: column;
}

.usageHeaderText {
  color: var(--color-y);
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.usageChart {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding: 10px 0;
}

.usageChartsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.usageTitle {
  color: var(--color-y);
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.subscriptionContainer {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  border: 2px solid var(--color-y);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow for depth */
  padding: 20px 30px; /* Increase padding for better spacing */
  border-radius: 12px; /* Slightly more rounded corners */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subscriptionContainer h4 {
  margin-top: 10px;
  color: var(--color-y); /* Change color to var(--color-y) */
  font-size: 1.8rem; /* Increase font size */
}

.planDetails p {
  margin: 0;
  color: var(--color-y);
  font-size: 1.1rem;
}

.upgradeButton {
  margin-top: 10px;
  background-color: var(--color-b);
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.upgradeButton:hover {
  background-color: var(--color-b-hover);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.progressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.usageGraph {
  flex: 1;
  width: 100%;
  height: 400px;
  margin-top: 20px;
}

.timeFrameButtons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 20px;
}

.timeFrameButton {
  background-color: #1b3b45;
  color: #ffffff;
  border: 1px solid #0c7489;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease;
}

.timeFrameButton:hover {
  background-color: #238099;
  border-color: #238099;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

.timeButtonSelected {
  background-color: #0c7489;
  border-color: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.customProgress {
  width: 100%;
  height: auto;
}

.progressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.circularWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  flex: 1; /* Allow it to flexibly take up available space */
}

.customProgress {
  width: 100%;
  height: auto;
}

.progressText {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none;
}

.countText {
  font-size: 2rem;
  color: var(--color-y);
}

.labelText {
  font-size: 1.6rem;
  color: var(--color-y);
  margin-top: 2px;
}

.customerQueries {
  background-color: rgba(0, 0, 0, 0.2); /* Subtle background color */
  border: 2px dashed var(--color-y); /* Dashed border to indicate an upcoming feature */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Add padding for spacing */
  text-align: center; /* Center the content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  margin-left: 30px;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.customerQueries h3 {
  color: var(--color-y); /* Use the theme's yellow color */
  font-size: 1.8rem; /* Match with other headings */
  margin-bottom: 10px;
}

.customerQueries p {
  font-size: 1.2rem; /* Slightly smaller text for additional details */
  color: var(--color-y); /* Use the theme's yellow color */
}

.customerQueries .comingSoonBadge {
  background-color: var(--color-b);
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 20px;
  margin-top: 15px;
  display: inline-block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
  .usageContent {
    flex-direction: column;
  }

  .customerQueries {
    order: 2;
    max-width: 100%;
    padding-right: 0;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .usageContainer {
    order: 1;
    width: 100%;
  }
  .circularWrapper {
    max-width: 80%;
  }
}

.pricingPageContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.pricingContent {
  background: var(--color-background);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-y);
  overflow-y: auto;
}

.pricingContent h1 {
  margin-top: 0;
  font-size: 3rem;
  font-weight: bold;
  color: var(--color-primary);
}

.pricingContent p {
  margin: 10px 0;
  font-size: 1.2rem;
  text-align: center;
  color: var(--color-secondary);
}

.plansContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.planCard {
  width: 280px; /* Ensure all cards have the same width */
  border: 2px solid var(--color-y-hover);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: var(--color-y);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planCard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.planCard h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: bold;
  color: var(--color-primary);
}

.planPrice {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 10px 0;
  color: var(--color-highlight);
}

.planCard p {
  margin: 10px 0;
  font-size: 1.1rem;
  color: var(--color-secondary);
}

.planButton {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: var(--color-a);
  color: var(--color-y);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.planButton:hover {
  background-color: var(--color-a-hover);
}

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dashboardContent {
  display: flex;
  flex: 1;
  overflow: hidden; /* Ensure no scroll for the entire content */
}

.outletContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scroll */
  background-color: var(--color-a);
}

@media (max-width: 768px) {
  .dashboardContent {
    flex-direction: column;
  }

  .outletContainer {
    flex: 1;
    overflow-y: auto; /* Ensure it scrolls if content overflows */
  }
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #1a2e3c;
}

::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 5px;
  border: 3px solid #1a2e3c;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Changed to move content higher */
  height: 100vh;
  background-color: #f0f4f8;
  padding: 20px;
  overflow: hidden; /* Prevents scrolling */
  padding-top: 20vh;
}

.title {
  font-size: 4.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.description {
  font-size: 2.25rem;
  color: #7f8c8d;
  text-align: center;
  max-width: 600px;
}

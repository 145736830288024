.urlDetails {
  background-color: #2a4d53;
  color: var(--color-y);
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.urlFormHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
}

.urlFormHeader span {
  flex: 1;
  text-align: center;
}

.urlFormHeader button {
  background-color: #76c7c0;
  border: none;
  border-radius: 50%;
  color: var(--color-y);
  cursor: pointer;
  font-size: 20px;
  padding: 0 10px;
}

.urlForm {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.urlForm input {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid var(--color-y);
  border-radius: 4px;
  background-color: #f2f2f2;
  color: var(--color-x);
  font-family: sans-serif;
}

.urlForm input:last-child {
  margin-right: 0;
}

.urlForm .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.urlForm .buttons button {
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: var(--color-y);
}

.urlForm .buttons .addButton {
  background-color: var(--color-a) !important;
}

.urlForm .buttons .cancelButton {
  background-color: var(--color-a) !important;
}

.urlForm .buttons .addButton:hover,
.urlForm .buttons .cancelButton:hover {
  background-color: var(--color-a-hover) !important;
}

.urlForm .error {
  flex-basis: 100%;
  margin-top: 10px;
  color: #ff6347;
  text-align: center;
}

.urlList {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.urlItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--color-y);
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #4a6b73;
}

.urlItem div {
  flex: 1;
  text-align: center;
}

.urlItem button {
  background-color: #76c7c0;
  border: none;
  border-radius: 4px;
  color: var(--color-y);
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
}

.urlItem button:last-child {
  margin-left: 0;
}

.urlItem input {
  flex: 1;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid var(--color-y);
  border-radius: 4px;
  background-color: var(--color-y);
  color: var(--color-x);
  font-family: sans-serif;
}

.urlItem input:last-child {
  margin-right: 0;
}

.deleteButton {
  background-color: var(--color-a) !important;
  border: none;
  border-radius: 50% !important;
  color: var(--color-y) !important;
  cursor: pointer;
  font-size: 12px !important;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.plusButton {
  background-color: var(--color-a) !important;
  border: none;
  border-radius: 0 !important;
  color: var(--color-y);
  cursor: pointer;
  font-size: 24px !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.editButton:hover,
.plusButton:hover,
.deleteButton:hover {
  background-color: var(--color-a-hover) !important;
}

.editButton {
  background-color: var(--color-a) !important;
  border: none;
  border-radius: 50% !important;
  color: var(--color-y);
  cursor: pointer;
  font-size: 12px !important;
  font-weight: bolder;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-right: 4px;
}

.urlLink {
  flex: 1;
  text-align: center;
  color: var(--color-y);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  border-radius: 4px;
  background-color: #4a6b73;
}

.saveButton {
  background-color: var(--color-a) !important;
  border: none;
  border-radius: 4px;
  color: var(--color-y);
  cursor: pointer;
  font-size: 14px !important;
  padding: 5px 10px;
  margin-right: 4px;
}

.cancelButton {
  background-color: var(--color-a) !important;
  border: none;
  border-radius: 4px;
  color: var(--color-y);
  cursor: pointer;
  font-size: 14px !important;
  padding: 5px 10px;
}

.addButton {
  background-color: var(--color-a) !important;
  border: none;
  border-radius: 4px;
  color: var(--color-y);
  cursor: pointer;
  font-size: 14px !important;
  padding: 5px 10px;
  margin-right: 4px;
}

.saveButton:hover,
.cancelButton:hover,
.addButton:hover {
  background-color: var(--color-a-hover) !important;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid var(--color-y);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .urlForm {
    flex-direction: column;
  }
  .urlForm input {
    flex: 1 1 100%;
    margin-bottom: 10px;
    width: 100%;
  }
  .urlForm input:last-child {
    margin-bottom: 0;
  }
  .urlForm .description-input {
    margin-top: 0;
  }
  .urlForm button {
    flex: 1;
    margin: 10px 0;
    width: 100%;
  }
  .urlForm .buttons {
    display: flex;
    justify-content: space-between;
    width: 50%;
    gap: 20px;
  }
}

.conversationsContainer {
  background-color: #335f7d;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  width: 250px;
}

.conversationsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.conversationItem {
  padding: 10px;
  margin: 0;
  background-color: #335f7d;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conversationItem:first-child {
  border-top: 1px solid #ffffff;
}

.conversationItem:last-child {
  border-bottom: 1px solid #ffffff;
}

.conversationItem:hover {
  background-color: #3b6978; /* Adjusted hover color */
}

.conversationItem:active,
.conversationItem.selected {
  background-color: #1a3a4a; /* Adjusted selected color */
}

.messageText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 10px;
}

.messageDate {
  color: #aaaaaa; /* Subtle gray color for date */
  flex-shrink: 0;
}

.revisionPrefix {
  color: #FFD700 ; /* Tomato color for emphasis */
  margin-right: 3px;
  font-weight: bold;
}

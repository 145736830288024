.passwordResetPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Arial", sans-serif;
  background: var(--color-y);
}

.navbar {
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--color-background);
}

.formContainer {
  background: var(--color-y);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.title {
  margin-bottom: 50px;
  font-size: 2em;
  font-weight: bold;
  color: var(--color-x);
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.userIcon {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  opacity: 0.6;
}

.input {
  width: 100% !important;
  padding: 10px 40px 10px 40px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  font-size: 1em !important;
  transition: border-color 0.3s !important;
  color: var(--color-x) !important;
  background-color: var(--color-y) !important;
}

.input:focus {
  border-color: var(--color-a);
}

.errorText {
  color: red;
  font-size: 0.9em;
  margin-top: -5px;
  margin-bottom: 20px;
  text-align: center;
}

.successText {
  color: green;
  font-size: 0.9em;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.resetButton {
  background: var(--color-a);
  color: var(--color-y);
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background 0.3s;
}

.resetButton:disabled {
  background: var(--color-a);
  color: var(--color-y);
  cursor: not-allowed;
  opacity: 0.7;
}

.resetButton:hover {
  background: var(--color-a-hover);
}

.linkButton {
  background: none;
  border: none;
  color: var(--color-a);
  cursor: pointer;
  font-size: 0.9em;
  text-decoration: underline;
}

.signInLinkContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .formContainer {
    background: var(--color-y);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    width: 100%;
  }

  .signInLinkContainer {
    justify-content: center;
  }
}

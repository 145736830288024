.signInPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Arial", sans-serif;
  background: var(--color-y);
}

.navbar {
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--color-background);
}

.formContainer {
  background: var(--color-y);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.title {
  margin-bottom: 50px;
  font-size: 2em;
  font-weight: bold;
  color: var(--color-x);
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.userIcon,
.passwordIcon {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  opacity: 0.6;
}

.viewIcon {
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  opacity: 0.6;
  cursor: pointer;
}

.input {
  width: 100% !important;
  padding: 10px 40px 10px 40px !important; /* Adjust left and right padding */
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  font-size: 1em !important;
  transition: border-color 0.3s !important;
  color: var(--color-x) !important;
  background-color: var(--color-y) !important;
}

.input:focus {
  border-color: var(--color-a);
}

.errorText {
  color: red;
  font-size: 0.9em;
  margin-bottom: 10px;
  text-align: center;
}

.successText {
  color: green;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.forgotPasswordContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.linkButton {
  background: none;
  border: none;
  color: var(--color-a);
  cursor: pointer;
  font-size: 0.9em;
  text-decoration: underline;
}

.signInButton {
  background: var(--color-a);
  color: var(--color-y);
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background 0.3s;
}

.signInButton:hover {
  background: var(--color-a-hover);
}

.signUpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.signUpText {
  margin-right: 10px;
}

.signUpButton {
  background: none;
  border: none;
  color: var(--color-a);
  cursor: pointer;
  font-size: 0.9em;
  margin-top: -3px;
  text-decoration: underline;
}

@media (max-width: 768px) {

  .formContainer {
    background: var(--color-y);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    width: 100%;
  }
}
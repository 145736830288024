.fileContainer {
  background-color: #2a4d53;
  color: var(--color-y);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.fileUploads .title {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left; /* Align the title text to the left */
}

.fileUploads .fileInput {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-y);
  border-radius: 4px;
  background-color: #f2f2f2;
  color: var(--color-x);
  margin-bottom: 20px;
  text-align: left; /* Align the file input text to the left */
}

.fileUploads .fileList {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.fileUploads .fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--color-y);
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #4a6b73;
  text-align: left; /* Align the file item text to the left */
}

.fileUploads .fileName {
  flex: 1;
  text-align: left; /* Ensure file names are aligned to the left */
}

.fileUploads .removeButton {
  background-color: var(--color-a) !important;
  border: none;
  border-radius: 50% !important;
  color: var(--color-y) !important;
  cursor: pointer;
  font-size: 12px !important;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.fileUploads .removeButton:hover {
  background-color: var(--color-a-hover) !important;
}

@media (max-width: 768px) {
  .fileUploads .fileInput {
    flex: 1 1 100%;
    margin-bottom: 10px;
    width: 100%;
  }

  .fileUploads .fileItem {
    flex-direction: column;
    align-items: flex-start;
  }

  .fileUploads .removeButton {
    margin-top: 10px;
    width: 100%;
  }
}

.introQuestions {
    background-color: #2a4d53;
    color: var(--color-y);
    border-radius: 8px;
  }
  
  .questionFormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .questionForm {
    border-radius: 8px;
    display: flex;
    align-items: center; 
    padding-bottom: 10px;
  }
  
  .questionForm input {
    width: 100%;
    line-height: 1.5;
    font-family: monospace;
    background-color: var(--color-y);
    color: var(--color-x);
    font-weight: bold; /* Make text bolder */
    font-family: Arial, sans-serif;
    border: 1px solid var(--color-y);
    border-radius: 4px;
    padding: 8px;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .plusButton {
    background-color: var(--color-a) !important;
    border: none;
    border-radius: 0 !important;
    color: var(--color-y);
    cursor: pointer;
    font-size: 24px !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 10px;
  }
  
  .editButton:hover,
  .plusButton:hover,
  .deleteButton:hover {
    background-color: var(--color-a-hover) !important;
  }
  
  
  .cancelButton:hover {
    background-color: var(--color-y-hover);
    color: #2a4d53;
  }
  
  .addButton:hover {
    background-color: var(--color-a-hover);
    color: var(--color-y);
  }
  
  .questionList {
    max-height: 200px;
    overflow-y: auto;
  }

  .questionItem {
    background-color: #4a6b73;
    color: var(--color-y);
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    word-wrap: break-word; 
    white-space: normal;
    height: auto; 
    display: flex; 
    align-items: center; 
    justify-content: space-between;
  }
  
  .questionItem div {
    flex-grow: 1;
  }
  
  .editButton,
  .deleteButton {
    background-color: var(--color-a) !important;
    border: none;
    border-radius: 50% !important;
    color: var(--color-y) !important;
    cursor: pointer;
    font-size: 12px !important;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 10px;
  }
  
  .editButton:hover,
  .deleteButton:hover {
    background-color: var(--color-a-hover) !important;
    color: #2a4d53;
  }
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--color-y); 
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  .questionItem input {
    flex: 1;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid var(--color-y);
    border-radius: 4px;
    background-color: var(--color-y);
    color: var(--color-x);
    font-family: sans-serif;
  }
  
  .questionItem input:last-child {
    margin-right: 0;
  }
  
  .saveButton {
    background-color: var(--color-a) !important;
    border: none;
    border-radius: 4px;
    color: var(--color-y);
    cursor: pointer;
    font-size: 14px !important;
    padding: 10px 10px;
    margin-left: 10px;
  }

  .generateButton {
    background-color: var(--color-a) !important;
    border: none;
    border-radius: 4px;
    color: var(--color-y);
    cursor: pointer;
    font-size: 14px !important;
    padding: 10px 10px;
    margin-left: 7px;
  }

  .saveButton:hover,
  .generateButton:hover {
    background-color: var(--color-a-hover) !important;
  }
  
  .cancelButton {
    background-color: var(--color-a) !important;
    border: none;
    border-radius: 4px;
    color: var(--color-y);
    cursor: pointer;
    font-size: 14px !important;
    padding: 10px 10px;
    margin-left: 10px;
  }
  
  .cancelButton:hover {
    background-color: var(--color-a-hover) !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.chatsBar {
  background-color: var(--color-a);
  color: var(--color-y);
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 5px;
}

.searchContainer {
  display: flex;
  align-items: start;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-b);
  flex-shrink: 0;
  background-color: var(--color-a);
  z-index: 1;
  position: sticky;
  top: 0;
}

.conversationsList {
  overflow-y: auto;
  flex-grow: 1;
  margin-right: -10px;
  padding-right: 2px;
  padding-left: 0px;
}

.conversation {
  cursor: pointer;
  border-bottom: 1px solid var(--color-y-hover);
  padding-bottom: 10px;
  background-color: var(--color-a);
  transition: background-color 0.2s ease;
}

.conversation:hover {
  background-color: var(--color-b-hover);
}

.conversation.selected {
  background-color: var(--color-b-hover);
}

.lastMessageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1; /* Allow the container to grow and fill the available space */
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.lastMessage {
  font-size: 14px;
  color: var(--color-y);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageDate {
  font-size: 12px;
  color: #b0b0b0;
  margin-left: 5px;
  flex-shrink: 0;
}

.resizeHandle {
  width: 10px;
  height: 100%;
  cursor: ew-resize;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.searchBar {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: #4a6b73;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 4px;
}

.searchBar::placeholder {
  color: #d3d3d3;
}

.toggleButton {
  padding: 8px;
  background-color: var(--color-b);
  color: var(--color-y);
  border: none;
  cursor: pointer;
}

.hideButton {
  margin-left: 10px;
  border-radius: 4px;
}

.hideButton:hover {
  background-color: var(--color-b-hover);
}

.showButton {
  background-color: #2D4D56;
  width: 20px;
  padding-left: 5px;
}

.showButton:hover {
  background-color: var(--color-a);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color-y);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-top: 10px;
  margin-bottom: 10px;
}

.loadingText {
  color: var(--color-y);
  font-size: 16px;
  text-align: center;
}

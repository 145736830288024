.chatsArea {
  background-color: var(--color-d);
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  overflow-x: hidden;
}

.messageContainer {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  word-wrap: break-word;
  word-break: break-word;
}

.text {
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.userContainer {
  align-self: flex-start;
  text-align: left;
}

.botContainer {
  align-self: flex-end;
  text-align: right;
}

.senderLabel {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--color-y);
}

.userLabel {
  align-self: flex-start; /* Aligns the label to the left of the message */
  text-align: left;
}

.botLabel {
  align-self: flex-end; /* Aligns the label to the right of the message */
  text-align: right;
}

.message {
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  position: relative; /* Add this to position the timestamp */
  padding-bottom: 25px;
  min-width: 50px;
  margin-bottom: 8px;
}


.userMessage {
  background-color: var(--color-y);
  color: var(--color-x);
  margin-right: auto;
}

.botMessage {
  background-color: var(--color-c);
  color: var(--color-y);
  margin-left: auto;
}

.text {
  margin-bottom: 5px;
  white-space: pre-wrap; /* Preserves \n characters */
}

.timestamp {
  font-size: 0.85em;
  color: gray;
  position: absolute;
  bottom: 6px; /* Adjusts the distance from the bottom of the message */
}

.userMessage .timestamp {
  right: 10px; /* Aligns the timestamp to the bottom right for user messages */
  text-align: right;
}

.botMessage .timestamp {
  right: 10px; /* Aligns the timestamp to the bottom left for bot messages */
  text-align: right;
  color: #e0e0e0;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid var(--color-a);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
